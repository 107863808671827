html,
body {
  color: #677077;
  background-color: #fafafa;
  font: 0.9rem/1.2 var(--font-family-sans-serif);
}

#root {
  min-height: 100%;
}

a {
  color: #2e5d9f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #434967;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #434967;
}

label {
  color: #434967;
}

input {
  border: none;
}

.navbar {
  margin-bottom: 0px;
  background-color: #3b3a50;
  border-bottom: 5px solid #f2b632;
}

.navbar-brand,
.navbar-brand:hover {
  color: #f2b632;
  font-weight: 700;
  text-shadow: 1px 1px #252839;
}

.nav-link {
  color: #b5b5b7;
}

.nav-link:hover,
.active {
  color: #e8eaf2;
}

.nav-tabs-link {
  color: #434967;
}

.nav-tabs-link.disabled {
  color: #e8eaf2;
}
.nav-tabs-link:hover {
  color: #b5b5b7;
}

.nav-tabs-link.active {
  color: #434967;
  font-weight: 600;
}

.page-link {
  color: #2e5d9f;
}

.page-item.active .page-link {
  background-color: #2e5d9f;
  border-color: #2e5d9f;
}

.sidebar-left {
  margin-left: 15px;
}

.bg-io-light-2 {
  background-color: #f1f2f7;
}
.bg-io-light-1 {
  background-color: #e8eaf2;
}
.bg-io-primary {
  background-color: #434967;
}
.bg-io-dark {
  background-color: #292930;
}
.sw-bg-light {
  background-color: #e8eaf2;
}

.list-group-item {
  background-color: #e8eaf2;
}

.list-group-item-action:hover {
  background-color: #f1f2f7;
  cursor: pointer;
}

.list-group-item.active {
  background-color: white;
  border-color: #0000001f;
  border-left-width: 1px;
  color: #434967;
  font-weight: 500;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #f1f2f7;
}

.table th {
  color: #434967;
}

.table-font-sm {
  font-size: 0.875rem;
}

.badge-primary {
  background-color: #434967;
  border-color: #434967;
}

.btn-primary {
  background-color: #2e5d9f;
  border-color: #2e5d9f;
}

.btn-success {
  background-color: #5daa68;
  border-color: #5daa68;
}

.btn-warning {
  background-color: #fbc748;
  border-color: #fbc748;
  color: #ffffff;
}

.btn-danger {
  background-color: #de4d44;
  border-color: #de4d44;
}

.text-primary {
  color: #2e5d9f !important;
}

.text-success {
  color: #5daa68 !important;
}

.text-warning {
  color: #fbc748 !important;
}

.text-danger {
  color: #de4d44 !important;
}

.card-header,
.card-footer {
  background-color: #e8eaf2;
}

.card-body {
  background-color: #f1f2f7;
}

.sw-block {
  width: 600px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.io-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(255, 255, 255, 0.15); */
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 940;
}

.io-modal-dialog {
  width: 600px;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  z-index: 950;
}

.io-modal-form {
  width: 80%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  z-index: 950;
}

.io-modal-display {
  width: 90%;
  height: 90%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  z-index: 950;
}

.clickable {
  cursor: pointer;
}

/* .form-control {
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
} */

.form-control:disabled {
  background-color: #fafafa;
}

@media print {
  html,
  body {
    color: #000;
    background-color: #fff;
    font-size: 14pt;
  }

  table {
    font-size: 14pt;
    line-height: 1.2;
  }

  h1 {
    color: #626172;
  }

  h1 {
    font-size: 48pt;
  }

  h2 {
    font-size: 36pt;
  }

  h3 {
    font-size: 18pt;
  }
}
